import { SET_BOOKING_CALENDAR } from "../actionTypes"
const initialState={
    bookingCalendar:[]
}

export const bookingCalendar = (state = initialState,action)=>{
    switch(action.type){
        case SET_BOOKING_CALENDAR:
            return{
                ...state,
                bookingCalendar:action.payload,
            }
            default:
                return state;
    }
}