import {
    SET_QUESTION,
    SET_WIDGET,
    SET_EMAILS,
    SET_MASTERS,
    SET_PRODUCTS_FOR_EMAIL
} from "../actionTypes";

const initialState = {
    widgets: [],
    questions: [],
    emails: [],
    masters: [],
    productsemail: [],
};

export const configurationReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_WIDGET:
            return {
                ...state,
                widgets: action.payload,
            };
        case SET_QUESTION:
            return {
                ...state,
                questions: action.payload,
            };
        case SET_EMAILS:
            return {
                ...state,
                emails: action.payload,
            };
        case SET_MASTERS:
            return {
                ...state,
                masters: action.payload,
            };
        case SET_PRODUCTS_FOR_EMAIL:
            return {
                ...state,
                productsemail: action.payload,
            };
        default:
            return state;
    }
};