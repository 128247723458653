export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FALIED = "LOGIN_FALIED";
export const LOGOUT = "LOGOUT";

export const API_REQUEST = "API_REQUEST";
export const SUCCESS_RESPONSE = "SUCCESS_RESPONSE";
export const ERROR_RESPONSE = "ERROR_RESPONSE";
export const SET_COMMON_STATUS = "SET_COMMON_STATUS";
export const SKIP_ONBOARDING = "SKIP_ONBOARDING";
export const SET_LOADING = "SET_LOADING";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_CURRENT_TEAM = "UPDATE_CURRENT_TEAM";
export const UPDATE_TEAMS = "UPDATE_TEAMS";

export const SET_CONSTANTS = "SET_CONSTANTS";
export const SET_TEAM_MEMBERS = "SET_TEAM_MEMBERS";
export const SET_TEAM_INVITATIONS = "SET_TEAM_INVITATIONS";
export const SET_MY_TEAM_INVITATIONS = "SET_MY_TEAM_INVITATIONS";

export const SET_RENTAL_PRODUCTS = "SET_RENTAL_PRODUCTS";
export const SET_EQUIPMENTS = "SET_EQUIPMENTS";
export const SET_PRICES = "SET_PRICES";
export const SET_DURATIONS = "SET_DURATIONS";
export const SET_AVAILABILITIES = "SET_AVAILABILITIES";
export const SET_ASSETS = "SET_ASSETS"; 
export const SET_EMAILS = "SET_EMAILS";
export const SET_MASTERS = "SET_MASTERS";
export const SET_QUESTION = "SET_QUESTION";
export const SET_PRODUCTS_FOR_EMAIL = "SET_PRODUCTS_FOR_EMAIL"
export const SET_ASSIGN_QUESTIONS = "SET_ASSIGN_QUESTIONS";
export const SET_WIDGET = "SET_WIDGET";

export const SET_BOOKING_CALENDAR = "SET_BOOKING_CALENDAR";